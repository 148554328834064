import React from 'react';
import {ButtonGroup,Grid, Stack, Button,CircularProgress, Paper, Typography, Skeleton } from '@mui/material';
import { useState, useEffect } from 'react';
import {getWeeklySummary} from '../services/api';
import WeeklyDashboard from '../components/WeeklySummary';
import { RenderDashboardHeader, WaitForData } from '../components/EMCRenderUtils';
import { GetWeekNumber,GetWeek, ResetSummary, FilterZeroes, Compare, CompareRatio } from '../components/EMCUtils';


const WeeklyDashboardPage=() =>{

  const [loading,setLoading] = useState(true);
  const [summary,setSummary] = useState([]);
  const [weeknum, setWeeknum] = useState(GetWeek());
  useEffect( async () => { setLoading(true);
    getWeeklySummary(weeknum)
        .then (t=> ResetSummary(t))
//        .then (t=> FilterZeroes(t))
        .then (t=> t.sort(CompareRatio))
        .then (t=> {setSummary(t); setLoading(false)})
  },[weeknum]);

const Header = () => {
  return(
    <Grid container sx={{border:'0px solid blue'}} >
        <Grid item xs={12} sx={{textAlign:'center'}}>
            <GetLastXWeekButtons/>
        </Grid>
    </Grid>
  );
}

const GetLastXWeekButtons = ({x=4}) => {
  let xweeks=[]
  var upperLimit= GetWeek();
  var lowerLimit= upperLimit > x ? upperLimit-x : 0; 
  for (var i=upperLimit; i>lowerLimit ; i--) {xweeks.push(i)}
  return(
         <Paper elevation={3} sx={{width:'100%'}} >
          <ButtonGroup aria-label="outlined primary button group" > 
            {xweeks.map(week => <Button key={week} variant={ week==weeknum? "contained" : "outlined"} onClick={() => setWeeknum(week)}> {week} </Button>) }
          </ButtonGroup>
        </Paper>
  );

}
  
    return (
      <>
      <RenderDashboardHeader maintext="Weekly Dashboard"/>
      <Header></Header>
      <WeeklyDashboard Summary={summary}/>
      <WaitForData show={loading}/>
      </>
    );
}

export default WeeklyDashboardPage;
