
export const ResetSummary = (response) => {
  return response.map (item => ( {...item, Summary:item.Summary[0].Summary} ))
}

export const FilterZeroes = (response) => {
  let fwr = response.filter(item => (item.Summary.Total > 0))
  return fwr;
}

export const Compare = (a,b) => {
  if (a.Summary.Total > b.Summary.Total)
    return -2;
  else if (a.Summary.Total < b.Summary.Total)
    return 0;
  else 
    return -1;
}

export const CompareRatio = (a,b) => {
  let aratio = (a.Summary.Total)/(a.prefs.targetpoints+1)
  let bratio = (b.Summary.Total)/(b.prefs.targetpoints+1)
  if (aratio > bratio)
    return -2;
  else if (aratio < bratio)
    return 0;
  else 
    return -1;
}

export const CompareRatioForDashBoard = (a,b) => {
  let aratio = (a.Total)/(a.prefs.targetpoints+1)
  let bratio = (b.Total)/(b.prefs.targetpoints+1)
  if (aratio > bratio)
    return -2;
  else if (aratio < bratio)
    return 0;
  else 
    return -1;
}
const reducer = (p,q) => {
 return p + q;
}

export const AugmentItemsForDashboard = (items) => {
  return items.map(item => AugmentItem(item));
}

const AugmentItem = (item) => {
  let tarray=[]
  tarray = item.Summary.map(a=>a.Summary.Total)
  item.Total =  tarray.reduce(reducer,0);
  item.totaltarget = item.Summary.length * item.prefs.targetpoints ;
  return item ;
}

export const limitString = (string = '', limit = 0) => {
  return (string.length > limit ? string.substring(0, limit-3) + '...' : string);
}


export const GetWeekNumber = ()  => {
    // Copy date so don't modify original
    var d = new Date() 
    var adjustment = d.getDay() == 1 ? -1 : 0
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    //return weekNo+adjustment;
    // Adjustment is now removed because we have instant transfer of activities from Strava
    return weekNo;
}

// Use One or the other implementation
export const GetWeek = function() {
  var date = new Date();
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return  Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export const augmentLeanUsers = (users) => {
	return users.map( u => ( { ...u, name: limitString(u.firstname+' '+ u.lastname, 12), label:u.firstname, active:u.prefs.active }));
}